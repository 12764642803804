<template>
  <v-container
    id="register"
    fluid
    tag="section"
    class="pt-0 mt-0"
  >
    <v-overlay
      v-if="loading"
      class="align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        size="64"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="snackbar"
      xs12
      multi-line
      :timeout="timeout"
      top
      color="orange"
    >
      {{ errorMessage }}
      <v-btn
        color="white"
        text
        @click="resetErrorResponse"
      >
        {{ $t('loginPage.close') }}
      </v-btn>
    </v-snackbar>
    <v-row
      class="d-flex justify-center"
    >
      <v-col
        cols="12"
        sm="10"
        md="9"
        lg="8"
        xl="6"
        class="px-1 d-flex justify-center"
      >
        <v-card
          ref="card"
          class="d-inline-flex"
          elevation="1"
          light
          width="100%"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              md="6"
            >
              <v-row
                no-gutters
                class="d-flex justify-center"
                style="background: #00A1B7;"
              >
                <v-col class="py-3 py-md-11 py-lg-6">
                  <v-img
                    src="/img/appbar-logo.png"
                    class="mx-auto"
                    width="241"
                    height="70"
                  ></v-img>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                class="d-flex justify-center"
              >
                <v-col>
                  <v-img
                    class="login-image d-none d-md-block rounded-bl"
                    src="/img/login/login-img-op.jpg"
                  ></v-img>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="pa-10"
            >
              <v-row
                class=""
              >
                <v-col class="title-text mb-1">
                  {{ $t('loginPage.loginInAccount') }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-form
                    ref="form"
                    @submit.prevent="submit"
                  >
                    <validation-observer
                      ref="observer"
                    >
                      <v-row class="d-flex justify-center">
                        <v-col
                          class="pb-0"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Email"
                            rules="required|email|disposable"
                          >
                            <v-text-field
                              ref="email"
                              v-model="email"
                              name="email"
                              :error-messages="errors"
                              :value="email"
                              placeholder=" "
                              persistent-placeholder
                              :label="$t('loginPage.email')"
                              outlined
                              prepend-inner-icon="mdi-email-outline"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center">
                        <v-col
                          class="pb-0"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            vid="password"
                            name="Password"
                            rules="required"
                          >
                            <v-text-field
                              ref="password"
                              v-model="password"
                              :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off'"
                              :error-messages="errors"
                              :type="show ? 'text' : 'password'"
                              :value="password"
                              placeholder=" "
                              persistent-placeholder
                              :label="$t('loginPage.password')"
                              name="password"
                              outlined
                              prepend-inner-icon="mdi-lock"
                              @click:append="show=!show"
                            >
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col
                          cols="6"
                          class=""
                        >
                          <v-checkbox
                            v-model="rememberMe"
                            class=" mt-0 pt-0"
                          >
                            <template v-slot:label>
                              <span class="c-checkbox">{{ $t('loginPage.rememberMe') }}</span>
                            </template>
                          </v-checkbox>
                        </v-col>
                        <v-col
                          cols="6"
                          class="text-right"
                        >
                          <div class="signup-text">
                            <a
                              text
                              class="primary--text"
                              @click="resetPage()"
                            >
                              {{ $t('loginPage.forgotPassword') }}
                            </a>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center mt-0 mb-3">
                        <v-col
                          cols="12"
                          class="d-flex justify-center"
                        >
                          <v-btn
                            class="primary whtie--text mr-0 login-btn-new"
                            block
                            large
                            type="submit"
                            :disabled="isDisabled"
                            @keyup.enter="submit"
                            @click="loginSubmit"
                          >
                            <span class="login-btn">{{ $t('loginPage.login') }}</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </validation-observer>
                  </v-form>
                  <!-- HIDE SOCIAL LOGINS -->
                  <v-row
                    no-gutters
                    align="center"
                    class="mt-4 d-none"
                  >
                    <v-col cols="4">
                      <v-img
                        src="/img/login/or.svg"
                        class=""
                      ></v-img>
                    </v-col>
                    <v-col cols="8">
                      <v-row
                        no-gutters
                        align="center"
                      >
                        <v-col cols="4">
                          <v-img
                            src="/img/login/g.svg"
                            class="social-icon-login"
                          ></v-img>
                        </v-col>
                        <v-col cols="4">
                          <v-img
                            src="/img/login/t.svg"
                            class="social-icon-login"
                          ></v-img>
                        </v-col>
                        <v-col cols="4">
                          <v-img
                            src="/img/login/fb.svg"
                            class="social-icon-login"
                          ></v-img>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-col>
                      <div class="signup-text">
                        <span style="color: #757575">{{ $t('loginPage.dontHaveAccount') }}</span> <button
                          text
                          class="primary--text"
                          @click="registerPage()"
                        >
                          {{ $t('loginPage.register') }}
                        </button>
                      </div>
                      <!-- <div class="signup-text">
                        <span style="color: #757575">Forgot Password?</span> <button
                          text
                          class="primary--text"
                          @click="resetPage()"
                        >
                          Reset
                        </button>
                      </div> -->
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!--------------------------------------MOBILE-------------------------------------->
    <!-- <v-row
      v-if="mobileDevice"
      class="d-flex justify-center"
    >
      <v-col
        cols="12"
        sm="8"
        md="6"
        class="px-1"
      >
        <v-card
          class="pa-6"
          elevation="1"
          light
        >
          <v-row class="d-flex justify-center">
            <v-col>
              <v-img
                src="/img/login-logo.svg"
                class="mx-auto"
                width="241"
                height="100"
              ></v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-form
                ref="form"
                @submit.prevent="submit"
              >
                <div
                  class="title-text text-center"
                >
                  <v-row class="mb-2">
                    <v-col>
                      Log in to Your Account
                    </v-col>
                  </v-row>
                </div>
              </v-form>
              <div>
                <v-form
                  ref="form"
                  @submit.prevent="submit"
                >
                  <validation-observer
                    ref="observer"
                    v-slot="{ invalid }"
                  >
                    <v-row class="d-flex justify-center">
                      <v-col
                        cols="12"
                        sm="10"
                        md="9"
                        class="pb-0"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Email"
                          rules="required|email"
                        >
                          <v-text-field
                            v-model="email"
                            label="Email"
                            outlined
                            append-icon="mdi-email-outline"
                            :error-messages="errors"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <v-col
                        cols="12"
                        sm="10"
                        md="9"
                        class="pb-0"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          vid="password"
                          name="Password"
                          rules="required"
                        >
                          <v-text-field
                            ref="password"
                            v-model="password"
                            name="password"
                            label="Password"
                            outlined
                            :type="show ?'text':
                              'password'"
                            :append-icon="show ?'mdi-eye-outline':'mdi-eye-off'"
                            :error-messages="errors"
                            @click:append="show=!show"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <v-col
                        cols="12"
                        sm="10"
                        md="9"
                      >
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <v-col
                        cols="6"
                        class="d-flex justify-center"
                      >
                        <v-btn
                          class="primary whtie--text mr-0"
                          block
                          large
                          :disabled="invalid"
                          @keyup.enter="submit"
                          @click="loginSubmit"
                        >
                          <span class="login-btn">Login</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </validation-observer>
                </v-form>
                <v-row class="d-flex justify-center">
                  <v-col>
                    <div class="signup-text text-center">
                      <span style="color: #757575">Don't have an account?</span> <button
                        text
                        class="primary--text"
                        @click="registerPage()"
                      >
                        register
                      </button>
                    </div>
                    <div class="signup-text text-center">
                      <span style="color: #757575">Forgot Password?</span> <button
                        text
                        class="primary--text"
                        @click="resetPage()"
                      >
                        reset
                      </button>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
  import { localize } from 'vee-validate'
  export default {
    name: 'PagesLogin',
    // components: {
    //   PagesBtn: () => import('./components/Btn'),
    //   // PagesHeading: () => import('./components/Heading'),
    // },
    data: () => ({
      autoFill: false,
      rememberMe: false,
      show: false,
      snackbar: false,
      timeout: 0,
      alertmessage: '',
      validationErrors: false,
      password: '',
      email: '',
      sections: [
        {
          icon: 'mdi-account-check',
          iconColor: 'primary',
          title: 'Create your account',
          text: 'Take full advantage of your Blanket'
        },
        {
          icon: 'mdi-file-document-multiple-outline',
          iconColor: 'secondary',
          title: 'Add policies',
          text: 'Add and review your policies in one place'
        },
        {
          icon: 'mdi-message',
          iconColor: 'green',
          title: 'Contact',
          text: 'Stay in touh with your Blanket agent, and get your questions answered. '
        }
      ],
      socials: [
        {
          href: '#',
          icon: 'mdi-facebook',
          iconColor: '#3B5998'
        },
        {
          href: '#',
          icon: 'mdi-twitter',
          iconColor: '#1DA1F2'
        },
      ]
    }),
    computed: {
      displayError () {
        console.log('error condition is ', this.$store.getters.getErrors)
        if (this.$store.getters.getErrors) {
          this.snackbar = true
          return true
        }
        return false
      },
      errorMessage () {
        let message = ''
        message = this.$store.getters.getErrors
        if (message) {
          this.snackbar = true
        }
        return message
      },
      isDisabled () {
        // First we check if we get autofill data and there are no validation errors
        if (this.autoFill && !this.validationErrors) return false
        // Then we check if we have a valid email and password (with autofill we don't have it anyway)
        if (!this.email || !this.password) return true
        // Then if we have a valid email and password we check if we have any validation errors
        if (this.validationErrors) return true

        // Otherwise we can login
        return false
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      user () {
        console.log('got a user this is login', this.$store.getters.user)
        return this.$store.getters.user
      },
      error () {
        return this.$store.getters.error
      },
      loading () {
        return this.$store.getters.getloading
      },
      loginResponse () {
        if (this.$store.getters.getloginerror) {
          this.snackbar = true
          this.alertmessage = this.$store.getters.getloginerror
        }
        return this.$store.getters.getloginerror
      }
    },
    watch: {
      user (value) {
        if (value !== null && value !== undefined) {
          console.log('we have a user ', this.$store.getters.user)
          const getRedirectUrl = localStorage.getItem('redirectUrl') ? localStorage.getItem('redirectUrl') : '/'
          this.$router.push(getRedirectUrl)
        }
      },
    },
    mounted () {
      this.autoLoginCheckingInterface()
      this.resetErrorResponse()
      localize(this.$i18n.locale)
    },
    methods: {
      autoLoginCheckingInterface () {
        // each 100ms we check if the issue was produced
        let intervalDetectAutofill = setInterval(() => {
          if (
            // we target at least one of the stuff that will be affected by autofill
            // to do our checking
            document.querySelectorAll('input[type="password"]:-webkit-autofill')
              .length > 0
          ) {
            // and we inform the system about the issue if it is produced
            this.autoFill = true

            // we stop to check if issue was produced
            clearInterval(intervalDetectAutofill)
          }
        }, 100)

        // if after 3s nothing appear, means no autofill was made
        setTimeout(() => {
          if (intervalDetectAutofill) {
            clearInterval(intervalDetectAutofill)
            intervalDetectAutofill = null
          }
        }, 3000)
      },
      resetPage () {
        this.$router.push({ path: '/pages/reset' })
      },
      registerPage () {
        this.$router.push({ path: '/pages/register' })
      },
      loginSubmit () {
        this.checking = true
        this.alertmessage = 'checking'
        this.$store.dispatch('signUserIn', { email: this.email, password: this.password })
        this.$gtag.event('login', { method: 'EmailPw' })
        this.checking = false
      },
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async validateFields () {
        const valid = await this.$refs.observer.validate()

        if (valid) {
          this.validationErrors = false
          return true
        }

        this.validationErrors = true
        return false
      },
      resetErrorResponse () {
        this.$store.dispatch('clearError')
        this.alertmessage = ''
        this.snackbar = false
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      onDismissed () {
        this.$store.dispatch('clearError')
      },
      async submit () {
        let complete = await this.$refs.observer.validate()
        if (!complete) {
          console.log('error submitting form')
        } else {
          console.log('logging in...')
          this.$store.dispatch('signUserIn', { email: this.email, password: this.password })
        }
        let errorstate = this.$store.getters.error
        /*
        setTimeout(() => {
          if (this.$store.getters.user) {
            console.log('WE HAVE A USER TO SEND HOME ', this.$store.getters.user)
            this.snackbar = true
            this.resetForm()
            this.$v.$reset()
            this.$router.push({
              name: 'dashboard/Dashboardv2'
            })
          }
        }, 5000)
        */
        this.checking = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .signup-text {
    margin-top: 0.725rem;
    font-size: 12px;
  }

  .social-icon-login {
    margin-left: 0.7rem;
  }

  .c-checkbox {
    color: #292f36 !important;
    margin-top: 0.725rem;
  }

  .login-btn-new {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 125%;
    color: #292f36 !important;
    background: #aaf0c1 !important;
  }
  .title-text {
    // font-family: 'Roboto';
    // font-style: normal;
    // font-weight: 400;
    // font-size: 24px;
    // line-height: 32px;
    // color: #424242;
    font-family: "Sora";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #292f36;
  }
  .light-grey {
    background-color: #fafafa;
  }

  // .v-sheet.v-card {
  //   border-top: 4px solid #00A1B7 !important;
  // }
</style>
